import { StaticQuery, graphql } from "gatsby"
import React, {useState,useEffect} from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import styled from 'styled-components';
import gsap from 'gsap';
import {supplyRandomPage} from "./randompage.js";
const linkLimit = 0
const TagLink = TransitionLink;

let imageStyle = {
  width: "100px",
  margin: "0 20px",
	position: "absolute",
	right: 0,
	bottom: 0,
	cursor: "pointer"
}

let tagStyle = {
  borderWidth: "1px",
  borderStyle: "solid",
  padding: "5px",
  margin: "0",
  borderColor: "#d3d3d3",
	textDecoration: "none",
  textAlign: "center",
  backgroundColor: "#f8f9f9",
}

const tagsStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, 90px)",
	justifyContent: "left",
  width: "calc(100vw - 150px)"
}

const updateHistory = (e,slug) => {
  e.stopPropagation();
  if(typeof window !== "undefined" && window.hasOwnProperty("localStorage")){
    let history = JSON.parse(localStorage.bt_history);
    let updatedFraudLinks = history["fraud"].filter(s => s !== slug);
    history["fraud"] = updatedFraudLinks;
    localStorage.bt_history = JSON.stringify(history);
  }
  if(typeof window !== "undefined" && window.bt_audio)
    window.bt_audio.src = "";
}

const onTagClick = (e) => {
  e.stopPropagation()
}

const getTagLinks = (relatedTags,limit=0,slug,staticQueryData) => {
  return relatedTags.map((relatedTag,i) => {
    const slugs = relatedTag.slugs
    const tagPartner = slugs[slug.length%slugs.length]
     return (slugs.length > 0 ?  (
      <TagLink entry={{ delay: .99 }} exit={{ length: 1 }}
			trigger={ async pages => {
				gsap.fromTo("#transition-canvas",{x:"-100vw"},{x:0, duration: 1})
        const entry = await pages.entry
        await entry.visible
				gsap.fromTo("#transition-canvas",{x:0},{x:"100vw", duration: 1})
    	}}
			className="tag"
			onClick={(e) => updateHistory(e,slug)}
			style={tagStyle}
			key={i}
			to={"/fraud"+supplyRandomPage(slug,"fraud",staticQueryData)}>
        <span>{relatedTag.tag}</span>
      </TagLink>
    ) : (<span role="button" tabIndex={0} onKeyDown={e => e.stopPropagation()} onClick={onTagClick} className="tag" to="/" style={tagStyle} key={i}>{relatedTag.tag}</span>))
  })
}

const Tags = ({ tags,slug, sound }) => {
  const [showTags, setShowTags] = useState(typeof window !== "undefined" && window.innerWidth<480 ? false: true);
	const [rendered, setRendered] = useState(false);
	const handleToggle = e => {
		if(sound && sound.stop){
			sound.stop();
		}
		if(showTags){
			gsap.to(".tag", {y: 'random(400,600)',x: 'random(-200,200)', rotation: 'random(-720,720)',duration: 1, onComplete: () => setShowTags(!showTags)});
		}
		else {
			setShowTags(!showTags)
				gsap.fromTo(".tag",{y: 'random(400,600)',x: 'random(-200,200)', rotation: 'random(-720,720)'}, {y: 0, x:0, rotation: 0,duration: 1, ease: "ease-in"});
		}
	}
	useEffect(() => {
    // Update the document title using the browser API
		if(!rendered){
	    gsap.fromTo(".tag", {y: 'random(400,600)',x: 'random(-200,200)', rotation: 'random(-720,720)'},{x: 0, y:0, rotation: 0, duration: 1, ease: "ease-in"});
			setRendered(true);
		}
  },[rendered]);
  return (
    <StaticQuery
        query={graphql`
          query HeadingQuery {
            allMarkdownRemark(filter: {fields: {table: {eq: "fraud"}}}) {
              group(field: frontmatter___tags) {
                tag: fieldValue
                totalCount
                edges {
                  node {
                    fields {
                      slug
											path
                    }
                  }
                }
              }
            }
            file(relativePath: { eq: "newTV2.gif" }) {
              publicURL
            }
            allLinks: allMarkdownRemark {
              group(field: fields___table) {
                distinct(field: fields___table)
                edges {
                  node {
                    fields {
                    	slug
                    }
                  }
                }
              }
            }
          }
        `}
        // onClick={ e => randomPage(e,"death",data.allMarkdownRemark)}
        render={ data => {
          let relatedTags = []
          let links
          let allTags = data.allMarkdownRemark.group
					let foundTags = tags.slice()
					// .filter(sl => sl.indexOf("alphabet") < 0 && sl.indexOf("house-46-stoutenberg-norwalk") < 0 && sl.indexOf("business penmanship") < 0)
          for (const node in allTags){
            if(tags.indexOf(allTags[node].tag) > -1){
              relatedTags.push({
                tag: allTags[node].tag,
                slugs: allTags[node].edges.map((edge) => edge.node.fields.slug).filter((sl) => {
                    return sl !== slug
                }).filter(sl => sl.indexOf("alphabet") < 0 && sl.indexOf("house-46-stoutenberg-norwalk") < 0 && sl.indexOf("business penmanship") < 0)
              })
							foundTags.splice(foundTags.indexOf(allTags[node].tag),1)
						}
          }
					for (const node in foundTags){
						relatedTags.push({
							tag: allTags[node].tag,
							slugs: []
						})
					}

          if(relatedTags){
            // console.log(relatedTags,linkLimit,slug,data)
            links = getTagLinks(relatedTags,linkLimit,slug,data.allLinks)
          }
          return (
            <div className={`tags-container ${showTags ? "show" : ""}`} style={{
              // position: "fixed",
              bottom: "0",
              textAlign: "center",
              width: "100vw"
            }} onClick={handleToggle} onKeyDown={handleToggle} role="button" tabIndex={0}>
              <div className="tags" style={{
                margin: "1em",
                opacity: (showTags? "1" : "0"),
                ...tagsStyle
              }}>
              {links}
              </div>
               <img style={imageStyle} src={data.file.publicURL} alt="tv"/>
            </div>
        )
        }}
      />
    )
}

export default Tags
