import React, {useEffect, useState, useMemo} from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Tags from "../tags"
export default function FraudNav ({frontmatter,fields,children}){
  let backgroundColor = frontmatter.background_color ? frontmatter.background_color : null;
  let backgroundStyle = frontmatter.background_style ? frontmatter.background_style : "cover";
  let backgroundImages = frontmatter.background_images ? frontmatter.background_images.map(e=>e.childImageSharp || e.publicURL) : null;
  let images = []
  if(backgroundImages){
    images = images.concat(backgroundImages)
  }
  let [bg,setBg] = useState({})

  useEffect((e) => {
    if(images.length){
      setBg(images[Math.floor(Math.random()*images.length)])
    }

    if(typeof window !== "undefined"){

    }
  },[])
  return <div id="fraud-nav" className={"page-wrapper page-"+(fields.slug ? fields.slug.slice(1,-1): "")}>
    <div className="page-bg">
      {bg.gatsbyImageData ? <GatsbyImage object-fit="cover" image={getImage(bg.gatsbyImageData)} alt="" /> : <div style={
        {backgroundImage: `url(${bg})`,
        height: "100vh",
        backgroundSize: "cover"
      }
      }></div>}
    </div>
    {children}
    <Tags tags={frontmatter.tags} slug={fields.slug}></Tags>
  </div>
}
